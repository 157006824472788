angular.module("app").service('_logicService', function ($rootScope, _v, _calendar, _view) {

    this.DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";

    /**
     * 2016-12-08T00:00:00.000
     * string will become javascript date
     * Thu Dec 08 2016 00:00:00 GMT+0300 (Russia TZ 2 Standard Time)
     *
     * 2016-12-08T23:59:59.999
     * string will become javascript date
     * Thu Dec 08 2016 23:59:59 GMT+0300 (Russia TZ 2 Standard Time)
     *
     */
    this.strToJavascriptDate = function (str) {
        if (_.isUndefined(str) || _.isNull(str) || str == '') {
            return null
        }

        return moment(str).toDate();
    };

    /**
     * Thu Dec 08 2016 23:59:59 GMT+0300 (Russia TZ 2 Standard Time)
     * javascript date will become moment
     * 2016-12-08T23:59:59+03:00
     *
     */
    this.javascriptDateToMoment = function (d) {
        if (_.isNull(d) || _.isUndefined(d)) {
            return null;
        }
        return moment.utc(d).subtract(d.getTimezoneOffset(), 'm');
    };

    this.momentUtcToDatepickerDate = function (d) {
        if (new Date().getTimezoneOffset() > 0) {
            return d.add(1, 'd').startOf('day').toDate();
        } else {
            return d.startOf('day').toDate();
        }
    };

    /**
     * Crop end date logic
     *
     * @param crop crop bean
     * @returns crop end date
     */
    this.cropTo = function(crop) {
        return crop.actualHarvestingDate ? moment.utc(crop.actualHarvestingDate) : moment.utc(crop.plannedHarvestingDate);
    };

    this.cropFromWithTasks = function (crop) {
        var result = moment.utc(crop.from);
        _.each(crop.tasks, function (task) {
            var t = moment.utc(task.from);
            if (t.isBefore(result)) {
                result = t;
            }
        });
        return result;
    };

    this.cropToWithTasks = function (crop) {
        var result = this.cropTo(crop);
        _.each(crop.tasks, function (task) {
            var t = moment.utc(task.to);
            if (t.isAfter(result)) {
                result = t;
            }
        });
        return result;
    };

    this.getOnSowingActivities = function () {
        return ['STAND', 'FENOLOGY', 'DISEASES'];
    };

    this.getSortedRegularActivities = function () {
        return ['NOTE', 'INSECTS', 'DISEASES', 'WEEDS', 'STAND', 'FENOLOGY'];
    };

    /**
     * Find crop exact on date
     *
     * @param from moment.js instance
     * @param to moment.js instance
     * @param crops crops
     * @returns crop who contains from..to interval
     */
    this.findCropNow = function(from, to, crops) {
        var result;
        crops.forEach(crop => {
            var cfrom = moment.utc(crop.seedingDate);
            var cto = this.cropTo(crop);

            if (from.isBetween(cfrom, cto, null, '[]') && to.isBetween(cfrom, cto, null, '[]')) {
                result = crop;
            }
        });

        return result;
    };

    /**
     * Find crop exact on date or closest crop in future
     *
     * @param from moment.js instance
     * @param to moment.js instance
     * @param crops crops
     * @returns crop who contains from..to interval
     */
    this.findCropNowAndFuture = function (from, to, crops) {
        var result = this.findCropNow(from, to, crops);
        if (!result) {
            crops.forEach(crop => {
                var cfrom = moment.utc(crop.seedingDate);
                if (from.isBefore(cfrom)) {
                    if (!result) {
                        result = crop;
                    } else {
                        if (cfrom.isBefore(moment.utc(result.seedingDate))) {
                            result = crop;
                        }
                    }
                }
            });
        }
        return result;
    };

    this.cropMonitorCycle = function(cropMonitor, crop) {
        var result = {from: moment.utc(cropMonitor.datePublished)};
        crop.tasks.forEach(task => {
            if (task.type == "cropmonitor" && task.status == "DONE") {
                var taskFrom = moment.utc(task.from);
                if (taskFrom.isAfter(result.from)) {
                    if (_.isUndefined(result.to) || result.to.isAfter(taskFrom)) {
                        result.to = taskFrom;
                    }
                }
            }
        });
        if (result.to) { //finish date comes from crop monitor
            result.to = result.to.subtract(1, 'ms');
        } else {
            result.to = this.cropTo(crop);
        }

        return result;
    };

    this.checkFreeDate = function(crop, from, to, idToExclude) {
        var result = true;
        _.each(crop.tasks, function(task) {
            if (idToExclude && task.id == idToExclude) {
                //continue
            } else {
                var tfrom = moment.utc(task.from);
                var tto = moment.utc(task.to);
                if (from.isSameOrBefore(tfrom) && to.isAfter(tfrom)) {
                    result = false;
                }
                if (from.isBefore(tto) && to.isSameOrAfter(tto)) {
                    result = false;
                }
                if (from.isSameOrAfter(tfrom) && to.isSameOrBefore(tto)) {
                    result = false;
                }
            }
        });
        return result;
    };

    this.checkFullSprayCycle = function(crop, from, to) {
        var result = true;

        var tasks = _.map(crop.tasks, function(t) {
            return {from:  moment.utc(t.from), to: moment.utc(t.to), type: t.type, sprayType: t.sprayType};
        });

        tasks = _.filter(tasks, function(t) {
            return t.from.isBefore(to);
        });

        _.each(tasks, function(task) {
            if (task.type == 'spray' && task.sprayType == 'PARTIAL') {
                result = false;
            }
            if (task.type == 'cropmonitor') {
                result = false;
            }
        });

        return result;
    };

    this.checkSprayCycle = function(crop, from, to) {
        var result = true;

        var tasks = _.map(crop.tasks, function(t) {
            return {from:  moment.utc(t.from), to: moment.utc(t.to), type: t.type, sprayType: t.sprayType};
        });

        tasks = _.filter(tasks, function(t) {
            return t.from.isSameOrAfter(to);
        });

        tasks = _.sortBy(tasks, 'from');

        if (_.size(tasks) > 0) {
            if (tasks[0].type == 'spray') {
                result = false;
            }
        }

        return result;
    };

    // 79.29166666666666 -> 79.292
    this.round = function (value, decimals) {
        decimals = decimals === undefined ? 3 : decimals;
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    };

});